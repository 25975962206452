import * as React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import SEO from "../components/SEO";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import Footer from "../components/Footer";
import Camels from "../assets/img/gates.png";

const PartnerWithUs = () => {
  const featuredCards = [
    {
      backgroundColor: "#2f5597",
      title: "Events & Festival Training",
      link: "/partner-with-us/event-and-festival-development-support",
    },
    {
      backgroundColor: "#2f5597",
      title: "Investment Facilitation",
      link: "/partner-with-us/investment-facilitation",
    },
    {
      backgroundColor: "#2f5597",
      title: "Tourism Innovation fund ",
      link: "/partner-with-us/tourism-innovation-fund",
    },
    // {
    //   backgroundColor: "#2f5597",
    //   title: "Domestic Marketing Campaign",
    //   link: "/partner-with-us/domestic-marketing-campaign",
    // },
    // {
    //   backgroundColor: "#2f5597",
    //   title: "National Tourism Strategy",
    //   link: "/partner-with-us/national-tourism-campaign",
    // },
    // {
    //   backgroundColor: "#2f5597",
    //   title: "Access to Finance for SMEs",
    //   link: "/partner-with-us/microfinance-program",
    // },
  ];
  const marketingCards = [
    {
      backgroundColor: "#2f5597",
      title: "Domestic Marketing Campaign",
      link: "/partner-with-us/domestic-marketing-campaign",
    },
    {
      backgroundColor: "#6d92c3",
      title: "Marketing Strategic Planning",
      link: "/partner-with-us/coming-soon",
    },

    {
      backgroundColor: "#6d92c3",
      title: "International Marketing Campaigns",
      link: "/partner-with-us/coming-soon",
    },
    {
      backgroundColor: "#6d92c3",
      title: "Destination / Regional Marketing Support",
      link: "/partner-with-us/coming-soon",
    },
    {
      backgroundColor: "#6d92c3",
      title: "Public Awareness Campaigns",
      link: "/partner-with-us/coming-soon",
    },
  ];
  const productCards = [
    {
      backgroundColor: "#2f5597",
      title: "Destination Development Planning",
      link: "/partner-with-us/destination-development-planning",
    },
    {
      backgroundColor: "#2f5597",
      title: "Event & Festival Development",
      link: "/partner-with-us/event-and-festival-development-support",
    },
    {
      backgroundColor: "#2f5597",
      title: "Tourism Innovation Fund",
      link: "/partner-with-us/tourism-innovation-fund",
    },

    {
      backgroundColor: "#6d92c3",
      title: "Tourism Infrastructure Development",
      link: "/partner-with-us/coming-soon",
    },
  ];
  const environmentCards = [
    {
      backgroundColor: "#2f5597",
      title: "National Tourism Strategy",
      link: "/partner-with-us/national-tourism-campaign",
    },
    {
      backgroundColor: "#2f5597",
      title: "Pathways to Professionalism (HR Skills Training)",
      link: "/partner-with-us/pathway-to-professionalism",
    },
    {
      backgroundColor: "#2f5597",
      title: "Tourism Industry Association Strengthening",
      link: "/partner-with-us/association-strengthening",
    },
    {
      backgroundColor: "#6d92c3",
      title: "Quality, Safety & Sustainability Standards",
      link: "/partner-with-us/coming-soon",
    },

    {
      backgroundColor: "#6d92c3",
      title: "Pathways to Profitability (SME Training & Assistance)",
      link: "/partner-with-us/coming-soon",
    },
    {
      backgroundColor: "#6d92c3",
      title: "Air Services Development",
      link: "/partner-with-us/coming-soon",
    },
    {
      backgroundColor: "#6d92c3",
      title: "Data-Driven Decision Making",
      link: "/partner-with-us/coming-soon",
    },
  ];
  const investmentCards = [
    {
      backgroundColor: "#2f5597",
      title: "Access to Finance for SMEs",
      link: "/partner-with-us/microfinance-program",
    },
    {
      backgroundColor: "#2f5597",
      title: "Investment Facilitation",
      link: "/partner-with-us/investment-facilitation",
    },
  ];
  const partnerMenuLinks = [
    {
      title: "Marketing Strategic Planning",
      link: "/partner-with-us/coming-soon",
    },
    {
      title: "International Marketing Campaigns",
      link: "/partner-with-us/coming-soon",
    },
    {
      title: "Domestic Marketing Campaign",
      link: "/partner-with-us/domestic-marketing-campaign",
    },
    {
      title: "Destination/Regional Marketing Support",
      link: "/partner-with-us/coming-soon",
    },
    {
      title: "Public Awareness Campaigns",
      link: "/partner-with-us/coming-soon",
    },
    {
      title: "Destination Development Planning",
      link: "/partner-with-us/destination-development-planning",
    },
    {
      title: "Tourism Innovation Fund",
      link: "/partner-with-us/tourism-innovation-fund",
    },
    {
      title: "Event & Festival Development",
      link: "/partner-with-us/event-and-festival-development-support",
    },
    {
      title: "Tourism Infrastructure Development",
      link: "/partner-with-us/coming-soon",
    },
    {
      title: "National Tourism Strategy",
      link: "/partner-with-us/national-tourism-campaign",
    },
    {
      title: "Tourism Industry Association Strengthening",
      link: "/partner-with-us/association-strengthening",
    },
    {
      title: "Quality, Safety & Sustainability Standards",
      link: "/partner-with-us/coming-soon",
    },
    {
      title: "Pathways to Professionalism",
      link: "/partner-with-us/pathway-to-professionalism",
    },
    {
      title: "Air Services Development",
      link: "/partner-with-us/coming-soon",
    },
    {
      title: "Data-Driven Decision Making",
      link: "/partner-with-us/coming-soon",
    },
    {
      title: "Access to Finance for SMEs",
      link: "/partner-with-us/microfinance-program",
    },
    {
      title: "Investment Facilitation",
      link: "/partner-with-us/investment-facilitation",
    },
    {
      title: "Pathways to Profitability (SME Training & Assistance)",
      link: "/partner-with-us/coming-soon",
    },
  ];

  const responsive = {
    0: { items: 1 },
    1024: { items: 4 },
  };
  return (
    <>
      <SEO title="Partner With Us | Visit Tunisia Project" />
      <Header />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-banner">
            <div
              style={{ display: "flex" }}
              id="block-assistancetoentreprises"
              className="block block-block-content block-block-contentbbbe56a9-7411-4d30-b6b6-041089750357"
            >
              <div
                className="intern-banner"
                style={{ width: "100%", height: "520px" }}
              >
                <img
                  src={Camels}
                  alt="camels"
                  style={{ filter: "brightness(60%)" }}
                />
                <div className="caption">
                  <h1 className="animate__animated animate__fadeInUp animate__slow">
                    <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                      Partner With Us
                    </div>
                  </h1>
                  <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                    <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                      <p>
                        Find{" "}
                        <span style={{ color: "#366197 !important" }}>
                          opportunities
                        </span>{" "}
                        to support and promote your business, association,
                        festival or destination
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <header
                style={{
                  zIndex: "1",
                  height: "520px",
                  overflowX: "auto",
                  width: "27%",
                  backgroundColor: "#F5F5F5",
                  marginLeft: "15px",
                }}
                className="header-wrapper-partners"
              >
                <nav className="navbar">
                  <div
                    style={{ width: "auto" }}
                    className="right-side d-flex flex-row flex-wrap justify-content-lg-end align-items-start"
                  >
                    <ul
                      className="navbar-nav level-1"
                      style={{
                        paddingRight: "0px",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      {partnerMenuLinks.map((link, index) => (
                        <li key={index} className="nav-item">
                          <a
                            href={link.link}
                            className="nav-link"
                            data-drupal-link-system-path="node/47"
                            style={{ fontSize: "0.85rem" }}
                          >
                            {link.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </nav>
              </header>
            </div>
          </div>
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <article
                data-history-node-id={48}
                role="article"
                className="node node--type-activities node--promoted node--view-mode-full"
              >
                <div className="node__content">
                  <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                    <div>
                      <section className="resources" id="grants">
                        <div className="container">
                          <h2
                            className="title-left aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              The USAID Visit Tunisia Activity offers a range of
                              partnership opportunities for tourism businesses,
                              associations, event and festival organizers, and
                              destinations.
                            </div>
                          </h2>
                          <div
                            className="section-paragh aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="d-flex flex-md-row flex-column">
                              <div className="left-side">
                                <p>
                                  From business support services, funding
                                  opportunities, matchmaking, public-private
                                  dialogue, trainings, and events, we invite
                                  Tunisian tourism stakeholders to partner with
                                  us to help build a diverse, competitive, and
                                  resilient tourism sector in Tunisia.
                                </p>
                              </div>
                              <div className="right-side d-flex justify-content-md-end">
                                <div className="ticket">
                                  <h3>
                                    <a href="/where-we-work" target="_blank">
                                      Where We Work
                                    </a>
                                  </h3>
                                  <a
                                    className="underlined-link"
                                    href="/where-we-work"
                                    target="_blank"
                                  >
                                    Learn More{" "}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h2
                            className="title-left aos-animate"
                            data-aos="fade-up"
                            style={{ marginTop: "3%" }}
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              Featured Opportunities
                            </div>
                          </h2>
                          <div
                            className="section-paragh aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="d-flex flex-md-row flex-column">
                              <div className="left-side">
                                <p>
                                  The Visit Tunisia Activity is launching
                                  several exciting initiatives in 2022 – check
                                  out our featured activities to see how to get
                                  involved! Click on the activities below to
                                  learn more about each one and to understand
                                  our partnership requirements.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            className="grants-section"
                            style={{ marginTop: "25px", height: "auto" }}
                          >
                            <div
                              className="d-flex flex-wrap"
                              style={{ justifyContent: "center" }}
                            >
                              <AliceCarousel
                                mouseTracking
                                infinite
                                autoPlay
                                animationDuration={9000}
                                responsive={responsive}
                                items={[
                                  <div
                                    className="item work-card-partners aos-animate"
                                    style={{
                                      width: "calc(80% - 20px)",
                                      position: "relative",
                                      backgroundColor: "#2f5597",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    data-aos="zoom-in-up"
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay={0}
                                  >
                                    <a
                                      href={
                                        "/partner-with-us/event-and-festival-development-support"
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        className="card-text"
                                        style={{
                                          bottom: "auto",
                                          left: "auto",
                                          right: "auto",
                                        }}
                                      >
                                        <h3
                                          style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                            width: "100%",
                                            height: "auto",
                                          }}
                                        >
                                          Events & Festival Training
                                        </h3>
                                      </div>
                                    </a>
                                  </div>,
                                  <div
                                    className="item work-card-partners aos-animate"
                                    style={{
                                      width: "calc(80% - 20px)",
                                      position: "relative",
                                      backgroundColor: "#2f5597",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    data-aos="zoom-in-up"
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay={0}
                                  >
                                    <a
                                      href={
                                        "/partner-with-us/investment-facilitation"
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        className="card-text"
                                        style={{
                                          bottom: "auto",
                                          left: "auto",
                                          right: "auto",
                                        }}
                                      >
                                        <h3
                                          style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                            width: "100%",
                                            height: "auto",
                                          }}
                                        >
                                          Investment Facilitation
                                        </h3>
                                      </div>
                                    </a>
                                  </div>,
                                  <div
                                    className="item work-card-partners aos-animate"
                                    style={{
                                      width: "calc(80% - 20px)",
                                      position: "relative",
                                      backgroundColor: "#2f5597",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    data-aos="zoom-in-up"
                                    data-aos-easing="ease-in-back"
                                    data-aos-delay={0}
                                  >
                                    <a
                                      href={
                                        "/partner-with-us/tourism-innovation-fund"
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        className="card-text"
                                        style={{
                                          bottom: "auto",
                                          left: "auto",
                                          right: "auto",
                                        }}
                                      >
                                        <h3
                                          style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                            width: "100%",
                                            height: "auto",
                                          }}
                                        >
                                          Tourism Innovation fund
                                        </h3>
                                      </div>
                                    </a>
                                  </div>,
                                ]}
                              />
                            </div>
                          </div>
                          <h2
                            className="title-left aos-animate"
                            data-aos="fade-up"
                            style={{ marginTop: "3%" }}
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              Explore Partnership Opportunities
                            </div>
                          </h2>
                          <div
                            className="section-paragh aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="d-flex flex-md-row flex-column">
                              <div className="left-side">
                                <p>
                                  Click on the activities below to learn more
                                  about each activity and to understand our
                                  partnership requirements. We will be adding
                                  new partnership opportunities throughout the
                                  duration of the project so be sure to{" "}
                                  <AnchorLink
                                    to="#newsletter"
                                    title="newsletter"
                                    className="anchor-link"
                                  >
                                    sign up for our newsletter
                                  </AnchorLink>{" "}
                                  to be notified of new opportunities and{" "}
                                  <a
                                    href="https://www.facebook.com/VisitTunisiaActivity/"
                                    target="_blank"
                                    style={{ color: "#bb042b" }}
                                  >
                                    follow us on social media.
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <h3
                            className="title-left aos-animate"
                            data-aos="fade-up"
                            style={{ marginTop: "3%", textAlign: "center" }}
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              MARKETING AND COMMUNICATIONS
                            </div>
                          </h3>
                          <div
                            className="grants-section"
                            style={{ marginTop: "25px", height: "auto" }}
                          >
                            <div
                              className="d-flex flex-wrap"
                              style={{ justifyContent: "center" }}
                            >
                              {marketingCards.map((partner, index) => (
                                <div
                                  key={index}
                                  className="item work-card-partners aos-animate"
                                  style={{
                                    position: "relative",
                                    backgroundColor: partner.backgroundColor,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  data-aos="zoom-in-up"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay={0}
                                >
                                  <a
                                    href={partner.link}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="card-text"
                                      style={{
                                        bottom: "auto",
                                        left: "auto",
                                        right: "auto",
                                      }}
                                    >
                                      <h3
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      >
                                        {partner.title}
                                      </h3>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                          <h3
                            className="title-left aos-animate"
                            data-aos="fade-up"
                            style={{ marginTop: "3%", textAlign: "center" }}
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              PRODUCT DEVELOPMENT
                            </div>
                          </h3>
                          <div
                            className="grants-section"
                            style={{ marginTop: "25px", height: "auto" }}
                          >
                            <div
                              className="d-flex flex-wrap"
                              style={{ justifyContent: "center" }}
                            >
                              {productCards.map((partner, index) => (
                                <div
                                  key={index}
                                  className="item work-card-partners aos-animate"
                                  style={{
                                    position: "relative",
                                    backgroundColor: partner.backgroundColor,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  data-aos="zoom-in-up"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay={0}
                                >
                                  <a
                                    href={partner.link}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="card-text"
                                      style={{
                                        bottom: "auto",
                                        left: "auto",
                                        right: "auto",
                                      }}
                                    >
                                      <h3
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      >
                                        {partner.title}
                                      </h3>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                          <h3
                            className="title-left aos-animate"
                            data-aos="fade-up"
                            style={{ marginTop: "3%", textAlign: "center" }}
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              ENABLING ENVIRONMENT
                            </div>
                          </h3>
                          <div
                            className="grants-section"
                            style={{ marginTop: "25px", height: "auto" }}
                          >
                            <div
                              className="d-flex flex-wrap"
                              style={{ justifyContent: "center" }}
                            >
                              {environmentCards.map((partner, index) => (
                                <div
                                  key={index}
                                  className="item work-card-partners aos-animate"
                                  style={{
                                    position: "relative",
                                    backgroundColor: partner.backgroundColor,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  data-aos="zoom-in-up"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay={0}
                                >
                                  <a
                                    href={partner.link}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="card-text"
                                      style={{
                                        bottom: "auto",
                                        left: "auto",
                                        right: "auto",
                                      }}
                                    >
                                      <h3
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      >
                                        {partner.title}
                                      </h3>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                          <h3
                            className="title-left aos-animate"
                            data-aos="fade-up"
                            style={{ marginTop: "3%", textAlign: "center" }}
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              INVESTMENT AND INNOVATION
                            </div>
                          </h3>
                          <div
                            className="grants-section"
                            style={{ marginTop: "25px", height: "auto" }}
                          >
                            <div
                              className="d-flex flex-wrap"
                              style={{ justifyContent: "center" }}
                            >
                              {investmentCards.map((partner, index) => (
                                <div
                                  key={index}
                                  className="item work-card-partners aos-animate"
                                  style={{
                                    position: "relative",
                                    backgroundColor: partner.backgroundColor,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  data-aos="zoom-in-up"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay={0}
                                >
                                  <a
                                    href={partner.link}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="card-text"
                                      style={{
                                        bottom: "auto",
                                        left: "auto",
                                        right: "auto",
                                      }}
                                    >
                                      <h3
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      >
                                        {partner.title}
                                      </h3>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};
export default PartnerWithUs;
